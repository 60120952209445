import React from "react";
import { Accordion, Button, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import CustomDateRangePicker from "../../components/commons/DateRangePicker";
import { HOME_HEALTH_COLUMNS } from "../../app/utils/OrderConfig";
import { HOME_HEALTH_ALL_STATUSES, HOME_HEALTH_CANCELLED_STATUSES, HOME_HEALTH_COMPLETED_STATUSES, isStatusDisabled, HOME_HEALTH_COMPLETED_AND_CANCELLED_ORER_STATUSES, HOME_HEALTH_EXCLUDE_UNSCHEDULED_ORDER_STATUSES, HOME_HEALTH_EXCLUDE_SCHEDULED_STATUSES, HOME_HEALTH_UNSCHEDULED_ORER_STATUSES } from "../../app/utils/Constants";
import OrdersGrid from "../../components/commons/OrdersGrid";
import { arraysEqual, ExportAllDataComponent } from "../StatOrder/statOrderList";
import RefreshPanel from "../StatOrder/refreshPanel";

const getSelectedFilterLabel = (data) => {
  let result = "";
  if (data && data.length > 0) {
    if (data.length === 1) {
      result = data[0].label;
    } else {
      result = `${data[0].label}, +${data.length - 1} more...`;
    }
  }
  return result;
}

class HomeHealthList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      enableMainFilter: false,
    };
  }

  toggleMainFilter = () => {
    this.setState({ enableMainFilter: !this.state.enableMainFilter })
  }

  toggleSelectAll = (e, isSelectedAll, allOptions = [], callback) => {
    e.preventDefault();
    let temp = [];
    if (isSelectedAll) {
      temp = [];
    } else {
      temp = allOptions;
    }
    callback(temp);
  }

  applyChanges = (e, value, callback) => {
    e.preventDefault();
    callback(value);
  }

  handleChangeStatus = (e, order_status, status) => {
    e.preventDefault();
    const { setHomeHealthTempStatus, setHighlightStatusApply } = this.props;
    let temp = [...order_status];
    if (temp.some(t => t.label == status.label)) {
      temp = temp.filter(t => t.label != status.label);
    } else {
      temp.push(status);
    }
    // if (temp.length) {
    //   setResetFilters(true);
    // }
    setHomeHealthTempStatus(temp);
    setHighlightStatusApply(true);
  }

  handleChangeState = (e, states, data) => {
    e.preventDefault();
    const { setHomeHealthTempState, setHighlightStateApply } = this.props;
    let temp = [...states];
    if (temp.some(t => t.value == data.value)) {
      temp = temp.filter(t => t.value != data.value);
    } else {
      temp.push(data);
    }
    setHomeHealthTempState(temp);
    setHighlightStateApply(true);
  };

  handleChangePartners = (e, partners, data) => {
    e.preventDefault();
    const { setHomeHealthTempPartner, setHighlightPartnerApply } = this.props;
    let temp = [...partners];
    if (temp.some(t => t.value == data.value)) {
      temp = temp.filter(t => t.value != data.value);
    } else {
      temp.push(data);
    }
    setHomeHealthTempPartner(temp);
    setHighlightPartnerApply(true);
  };

  handleChangeRegion = (e, region, data) => {
    e.preventDefault();
    const { setHomeHealthTempRegion, setHighlightRegionApply } = this.props;
    let temp = [...region];
    if (temp.some(t => t.value == data.value)) {
      temp = temp.filter(t => t.value != data.value);
    } else {
      temp.push(data);
    }
    setHomeHealthTempRegion(temp);
    setHighlightRegionApply(true);
  }

  handleServerSideExport = () => {
    const { orderType, performExport } = this.props;
    performExport(orderType);
  }

  render() {
    const {
      data = [],
      homeHealthListForModal = [],
      loading = false,
      editModal,
      type,
      order_status = [],
      region = [],
      states = [],
      partner = [],
      orderStatus,
      orderRegion,
      orderState,
      orderPartner,
      setOrderStatus,
      orderStartDate,
      orderEndDate,
      setOrderStartDate,
      setOrderEndDate,
      setIsClicked,
      isClicked,
      updateFilterInLocal,
      openHomeHealth,
      unscheduledHomeHealth,
      dateRef,
      dateRef2,
      isModalOpen,
      setOrderDateType,
      orderDateType,
      setHelpModal,
      allOrders,
      cancelledOrders,
      droppedOffOrders,
      setOrderRegion,
      setHomeHealthState,
      setOrderPartner,
      highlightStatusApply,
      highlightRegionApply,
      highlightStateApply,
      highlightPartnerApply,

      setHighlightStatusApply,
      setHighlightRegionApply,
      setHighlightStateApply,
      setHighlightPartnerApply,

      homeHealthTempStatus,
      setHomeHealthTempStatus,
      homeHealthTempRegion,
      setHomeHealthTempRegion,
      homeHealthTempState,
      setHomeHealthTempState,
      homeHealthTempPartner,
      setHomeHealthTempPartner,
      getInitialListingData,
      orderType,
      isExportDisabled,
      totalOrderCount
    } = this.props;
    const { enableMainFilter } = this.state;

    const orderStatusLabel = getSelectedFilterLabel(orderStatus);
    const orderPartnerLabel = getSelectedFilterLabel(orderPartner);
    const orderRegionLabel = getSelectedFilterLabel(orderRegion);
    const orderStateLabel = getSelectedFilterLabel(orderState);
    let today = new Date();
    today.setDate(today.getDate() - 1);

    // first get all statuses list
    let allStatuses = [];
    if (order_status) {
      order_status.forEach(status => {
        let disableStatus = isStatusDisabled(status.label, openHomeHealth ? HOME_HEALTH_COMPLETED_AND_CANCELLED_ORER_STATUSES : unscheduledHomeHealth ? HOME_HEALTH_EXCLUDE_UNSCHEDULED_ORDER_STATUSES : orderDateType ? HOME_HEALTH_EXCLUDE_SCHEDULED_STATUSES : []);
        if (allOrders) {
          if (orderDateType) {
            disableStatus = isStatusDisabled(status.label, HOME_HEALTH_EXCLUDE_SCHEDULED_STATUSES);
          } else {
            disableStatus = isStatusDisabled(status.label, HOME_HEALTH_ALL_STATUSES, "negative");
          }
        } else if (cancelledOrders && droppedOffOrders) {
          disableStatus = isStatusDisabled(status.label, [...HOME_HEALTH_CANCELLED_STATUSES, ...HOME_HEALTH_COMPLETED_STATUSES], "negative");
        } else if (droppedOffOrders || cancelledOrders) {
          disableStatus = isStatusDisabled(status.label, cancelledOrders ? HOME_HEALTH_CANCELLED_STATUSES : droppedOffOrders ? HOME_HEALTH_COMPLETED_STATUSES : [], "negative");
        }

        // if disableStatus is false, then push to the new list
        if (!disableStatus) {
          allStatuses.push(status);
        }
      });
    }
    // check if all statuses are selected already
    let selectedStatuses = homeHealthTempStatus ? [...homeHealthTempStatus] : [];
    const isSelectedAllStatuses = arraysEqual(allStatuses, selectedStatuses);

    // check if all regions are selected already
    let selectedRegions = homeHealthTempRegion ? [...homeHealthTempRegion] : [];
    const isSelectedAllRegions = selectedRegions && selectedRegions.length && arraysEqual(region, selectedRegions);

    // check if all states are selected already
    let selectedStates = homeHealthTempState ? [...homeHealthTempState] : [];
    const isSelectedAllStates = selectedStates && selectedStates.length && arraysEqual(states, selectedStates);

    // check if all partners are selected already
    let selectedPartners = homeHealthTempPartner ? [...homeHealthTempPartner] : [];
    const isSelectedAllPartners = selectedPartners && selectedPartners.length && arraysEqual(partner, selectedPartners);



    return (
      <React.Fragment>
        {enableMainFilter && <div className="st-left-filter-wrapper homehealth-filter">
          <div className="d-flex justify-content-between st-left-top-wrapper">
            <span className="">
              <span className="filter-icon">
                <span className="visually-hidden">Filter icon</span>
              </span>
              <span className="st-filterby-text font-size-13">Filter By:</span>
            </span>
            <div className="filter-reset-btn">

              <Button
                className="primary-btn primary-btn-outline outline st-reset-all-btn"
                onClick={() => {
                  setIsClicked(true);
                }}
              >
                <span className="reset-all pe-1">
                  <span className="visually-hidden">reset-icon</span>
                </span>  Reset All
              </Button></div>
            {/* <span className="reset-icon">
                <span className="visually-hidden">Reset icon</span>
              </span>
            <span className="st-filterby-text font-size-13">Reset All</span>
            </span> */}
          </div>
          <div className="stat-filter-wrapper">
            <ExportAllDataComponent isExportDisabled={isExportDisabled || !data ||!data.length} handleServerSideExport={this.handleServerSideExport} />
            {!openHomeHealth && !unscheduledHomeHealth && <div
              className={`ad-toggle-switch d-flex align-items-center up-ad-toggle-switch pe-0`}
            >
              <span className={`ad-switch font-size-12 roboto-regular me-2  ${!orderDateType ? `roboto-bold` : ""} `}>Order Created</span>
              <label
                className={`switch position-relative `}
              >
                <span className="visually-hidden">
                  Order Created
                </span>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    const currData = {
                      orderDateType: e.target.checked
                    }
                    if (e.target.checked) {
                      let tempStatus = orderStatus || [];
                      tempStatus = tempStatus.filter(st => st.label !== "Unscheduled" && st.label !== "Assigned-To-Coordinator");
                      currData.orderStatus = tempStatus;
                      setHomeHealthTempStatus(tempStatus);
                      setOrderStatus(tempStatus);
                    } else {
                      if (allOrders) {
                        let tempStatus = orderStatus || [];
                        tempStatus = [...tempStatus, ...HOME_HEALTH_UNSCHEDULED_ORER_STATUSES];
                        currData.orderStatus = tempStatus;
                        setHomeHealthTempStatus(tempStatus);
                        setOrderStatus(tempStatus);
                      }
                    }
                    updateFilterInLocal(currData);
                    setOrderDateType(e.target.checked);
                  }}
                  value={orderDateType}
                  checked={orderDateType}
                />
                <span className="slider round"></span>
              </label>
              <span className={`ad-switch font-size-12 roboto-regular ms-2  ${orderDateType ? `roboto-bold` : ""}`}>Schedule Date</span>
            </div>}
            {!openHomeHealth && !unscheduledHomeHealth && <InputGroup className="stat-input-group">
              <CustomDateRangePicker
                startDate={orderStartDate}
                endDate={orderEndDate}
                onApply={(e) => {
                  let value = e.target.value || "";
                  let start = value.split("-")[0];
                  let end = value.split("-")[1];
                  updateFilterInLocal({
                    orderStartDate: new Date(start),
                    orderEndDate: new Date(end),
                    dateChangedLastTime: new Date()
                  });
                  setOrderStartDate(new Date(start));
                  setOrderEndDate(new Date(end));
                }}
                dateRef={dateRef}
                // disabled={openRoutineRedraws}
                id="home-daterange"
                value={
                  orderStartDate && orderEndDate
                    ? `${moment(orderStartDate).format(
                      "MM/DD/yyyy"
                    )} - ${moment(orderEndDate).format("MM/DD/yyyy")}`
                    : ""
                }
                key={"a1b2c3d4"}
              />
            </InputGroup>}
            <div className="st-filter-accordions">
              <Accordion className="st-accordian-wrapper">
                <Accordion.Item eventKey="order_status">
                  <Accordion.Header className="routine-draws">
                    <span>Status</span>

                  </Accordion.Header>
                  <Accordion.Body className="mt-2" style={{ position: "static" }}>
                    <div className="filter-reset-btn d-flex justify-content-between mb-3">
                      <button type="button" className="primary-btn primary-btn-outline outline st-reset-all-btn btn btn-primary" onClick={e => this.toggleSelectAll(e, isSelectedAllStatuses, allStatuses, (newValue) => {
                        setHomeHealthTempStatus(newValue)
                        setHighlightStatusApply(true);
                      })}>{isSelectedAllStatuses ? "Unselect All" : "Select All"}</button>
                      {highlightStatusApply && <button type="button" className="primary-btn primary-btn-outline outline st-reset-all-btn btn btn-primary p-1" onClick={e => this.applyChanges(e, homeHealthTempStatus, (newValue) => {
                        updateFilterInLocal({ orderStatus: newValue });
                        setOrderStatus(newValue);
                        setHighlightStatusApply(false);
                      })}>Apply</button>}
                    </div>
                    <div>
                      {order_status.map((status, index) => {
                        const { label, value } = status;
                        let disableStatus = isStatusDisabled(status.label, openHomeHealth ? HOME_HEALTH_COMPLETED_AND_CANCELLED_ORER_STATUSES : unscheduledHomeHealth ? HOME_HEALTH_EXCLUDE_UNSCHEDULED_ORDER_STATUSES : orderDateType ? HOME_HEALTH_EXCLUDE_SCHEDULED_STATUSES : []);
                        if (allOrders) {
                          if (orderDateType) {
                            disableStatus = isStatusDisabled(status.label, HOME_HEALTH_EXCLUDE_SCHEDULED_STATUSES);
                          } else {
                            disableStatus = isStatusDisabled(status.label, HOME_HEALTH_ALL_STATUSES, "negative");
                          }
                        } else if (cancelledOrders && droppedOffOrders) {
                          disableStatus = isStatusDisabled(status.label, [...HOME_HEALTH_CANCELLED_STATUSES, ...HOME_HEALTH_COMPLETED_STATUSES], "negative");
                        } else if (droppedOffOrders || cancelledOrders) {
                          disableStatus = isStatusDisabled(status.label, cancelledOrders ? HOME_HEALTH_CANCELLED_STATUSES : droppedOffOrders ? HOME_HEALTH_COMPLETED_STATUSES : [], "negative");
                        }
                        return (
                          <div key={status.label} className="ml-weekday st-filter-checkbox-wrapper" style={disableStatus ? { opacity: 0.5, pointerEvents: "none" } : {}}>
                            <label className="ml-label-container st-label-container"
                              htmlFor={`st-status-${status.label}`}
                              onClick={e => this.handleChangeStatus(e, homeHealthTempStatus, status)}
                            >
                              <input
                                style={{ display: "block !important" }}
                                type="checkbox"
                                id={`st-status-${status.label}`}
                                data-testid="field-timings"
                                checked={homeHealthTempStatus ? homeHealthTempStatus.some(st => st.label == label) : false}
                              />
                              <span className="ml-checkmark"></span>
                              <span className="st-checkbox-label">{label}</span>
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion className="st-accordian-wrapper">
                <Accordion.Item eventKey="region">
                  <Accordion.Header className="routine-draws">
                    <span>Region</span>
                  </Accordion.Header>
                  <Accordion.Body style={{ position: "static" }}>
                    <div className="filter-reset-btn d-flex justify-content-between mb-3">
                      <button type="button" className="primary-btn primary-btn-outline outline st-reset-all-btn btn btn-primary" onClick={e => this.toggleSelectAll(e, isSelectedAllRegions, region, (newValue) => {
                        setHomeHealthTempRegion(newValue);
                        setHighlightRegionApply(true);
                      })}>{isSelectedAllRegions ? "Unselect All" : "Select All"}</button>
                      {highlightRegionApply && <button type="button" className="primary-btn primary-btn-outline outline st-reset-all-btn btn btn-primary p-1" onClick={e => this.applyChanges(e, homeHealthTempRegion, (newValue) => {
                        updateFilterInLocal({ orderRegion: newValue });
                        setOrderRegion(newValue);
                        setHighlightRegionApply(false);
                      })}>Apply</button>}
                    </div>
                    <div>
                      {region.map((data, index) => {
                        const { label, value } = data;
                        return (
                          <div key={value} className="ml-weekday st-filter-checkbox-wrapper" >
                            <label className="ml-label-container st-label-container"
                              htmlFor={`st-reg-${value}`}
                              onClick={e => this.handleChangeRegion(e, homeHealthTempRegion, data)}
                            >
                              <input
                                style={{ display: "block !important" }}
                                type="checkbox"
                                id={`st-reg-${value}`}
                                data-testid="field-timings"
                                checked={homeHealthTempRegion ? homeHealthTempRegion.some(st => st.value == value) : false}
                              />
                              <span className="ml-checkmark"></span>
                              <span className="st-checkbox-label">{label}</span>
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion className="st-accordian-wrapper">
                <Accordion.Item eventKey="states">
                  <Accordion.Header className="routine-draws">
                    <span>State</span>
                  </Accordion.Header>
                  <Accordion.Body style={{ position: "static" }}>
                    <div className="filter-reset-btn d-flex justify-content-between mb-3">
                      <button type="button" className="primary-btn primary-btn-outline outline st-reset-all-btn btn btn-primary" onClick={e => this.toggleSelectAll(e, isSelectedAllStates, states, (newValue) => {
                        setHomeHealthTempState(newValue);
                        setHighlightStateApply(true);
                      })}>{isSelectedAllStates ? "Unselect All" : "Select All"}</button>
                      {highlightStateApply && <button type="button" className="primary-btn primary-btn-outline outline st-reset-all-btn btn btn-primary p-1" onClick={e => this.applyChanges(e, homeHealthTempState, (newValue) => {
                        updateFilterInLocal({ orderState: newValue });
                        setHomeHealthState(newValue);
                        setHighlightStateApply(false);
                      })}>Apply</button>}
                    </div>
                    <div>
                      {states.map((data, index) => {
                        const { label, value } = data;
                        return (
                          <div key={value} className="ml-weekday st-filter-checkbox-wrapper" >
                            <label className="ml-label-container st-label-container"
                              htmlFor={`st-color-${value}`}
                              onClick={e => this.handleChangeState(e, homeHealthTempState, data)}
                            >
                              <input
                                style={{ display: "block !important" }}
                                type="checkbox"
                                id={`st-color-${value}`}
                                data-testid="field-timings"
                                checked={homeHealthTempState ? homeHealthTempState.some(st => st.value == value) : false}
                              />
                              <span className="ml-checkmark"></span>
                              <span className="st-checkbox-label">{label}</span>
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion className="st-accordian-wrapper">
                <Accordion.Item eventKey="partner">
                  <Accordion.Header className="routine-draws">
                    <span>Partner</span>
                  </Accordion.Header>
                  <Accordion.Body style={{ position: "static" }}>
                    <div className="filter-reset-btn d-flex justify-content-between mb-3">
                      <button type="button" className="primary-btn primary-btn-outline outline st-reset-all-btn btn btn-primary" onClick={e => this.toggleSelectAll(e, isSelectedAllPartners, partner, (newValue) => {
                        setHomeHealthTempPartner(newValue)
                        setHighlightPartnerApply(true);
                      })}>{isSelectedAllPartners ? "Unselect All" : "Select All"}</button>
                      {highlightPartnerApply && <button type="button" className="primary-btn primary-btn-outline outline st-reset-all-btn btn btn-primary p-1" onClick={e => this.applyChanges(e, homeHealthTempPartner, (newValue) => {
                        updateFilterInLocal({ orderPartner: newValue });
                        setOrderPartner(newValue);
                        setHighlightPartnerApply(false);
                      })}>Apply</button>}
                    </div>
                    <div>
                      {partner.map((data, index) => {
                        const { label, value } = data;
                        return (
                          <div key={value} className="ml-weekday st-filter-checkbox-wrapper" >
                            <label className="ml-label-container st-label-container"
                              htmlFor={`st-color-${value}`}
                              onClick={e => this.handleChangePartners(e, homeHealthTempPartner, data)}
                            >
                              <input
                                style={{ display: "block !important" }}
                                type="checkbox"
                                id={`st-color-${value}`}
                                data-testid="field-timings"
                                checked={homeHealthTempPartner ? homeHealthTempPartner.some(st => st.value == value) : false}
                              />
                              <span className="ml-checkmark"></span>
                              <span className="st-checkbox-label">{label}</span>
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>}
        <div className={`stat-order-filter-wrapper position-relative homehealth-filter-wrapper ${enableMainFilter ? 'filter-expanded' : 'filter-collapsed'} ${!openHomeHealth && orderStatusLabel && orderRegionLabel && orderStateLabel && orderPartnerLabel ? 'has-more-filter' : ''}`}>
          <div onClick={e => this.toggleMainFilter()} className="st-filter-expand">
            <div className=" d-flex align-items-center flex-column justify-content-center h-100 position-relative">
              <span className="filter-expand st-filter-expand-icon"><span className="visually-hidden">Filter-expand</span></span>
              <span className="st-filters-text">Filters</span>
            </div>
          </div>
          <div className="st-order-right-wrapper">
            <div className="st-filter-selected-wrapper d-flex align-items-center justify-content-between">
              <div className="d-flex flex-fill overflow-auto">
                <div className="filter-reset-btn me-2">
                  <Button
                    className="primary-btn primary-btn-outline outline st-reset-all-btn"
                    onClick={() => setIsClicked(true)}
                  > <span className="reset-all pe-1">
                      <span className="visually-hidden">reset-icon</span>
                    </span> Reset All
                  </Button></div>
                <ul className={`st-filter-selected-info-wrapper d-flex align-items-center ${!openHomeHealth && orderStatusLabel && orderRegionLabel && orderStateLabel && orderPartnerLabel ? 'hh-has-more-filter' : ''}`}>
                  {!openHomeHealth && !unscheduledHomeHealth && <li className="st-filter-selected-info"><span className="st-filter-title">{orderDateType ? "Schedule Date" : "Order Created"}:</span><span className="st-selected-value">{orderStartDate && orderEndDate
                    ? `${moment(orderStartDate).format(
                      "MM/DD/yyyy"
                    )} - ${moment(orderEndDate).format("MM/DD/yyyy")}`
                    : ""}</span></li>}
                  {orderStatusLabel && <li className={`st-filter-selected-info ${orderStatusLabel ? "" : "value-empty"}`}><span className="st-filter-title">Status:</span> <span className={`st-selected-value `}>
                    <OverlayTrigger
                      overlay={
                        <Tooltip
                          id="tooltip-disabled-status"
                          className="st-value-tooltip st-tooltip-btm "
                        >
                          <p>
                            {orderStatus ? orderStatus.map(val => val.label).join(", ") : ""}
                          </p>
                        </Tooltip>
                      }
                    ><span>{orderStatusLabel}</span></OverlayTrigger></span></li>}
                  {orderRegionLabel && <li className={`st-filter-selected-info ${orderRegionLabel ? "" : "value-empty"}`}><span className="st-filter-title">Region:</span> <span className={`st-selected-value `}><OverlayTrigger
                    overlay={
                      <Tooltip
                        id="tooltip-disabled-status"
                        className="st-value-tooltip st-tooltip-btm"
                      >
                        <p>
                          {orderRegion ? orderRegion.map(val => val.label).join(", ") : ""}
                        </p>
                      </Tooltip>
                    }
                  ><span>{orderRegionLabel}</span></OverlayTrigger></span></li>}
                  {orderStateLabel && <li className={`st-filter-selected-info  ${orderStateLabel ? "" : "value-empty"}`}><span className="st-filter-title">State:</span> <span className={`st-selected-value`}><OverlayTrigger
                    overlay={
                      <Tooltip
                        id="tooltip-disabled-state"
                        className="st-value-tooltip st-tooltip-btm"
                      >
                        <p>
                          {orderState ? orderState.map(val => val.label).join(", ") : ""}
                        </p>
                      </Tooltip>
                    }
                  ><span>{orderStateLabel}</span></OverlayTrigger></span></li>}
                  {orderPartnerLabel && <li className={`st-filter-selected-info ${orderPartnerLabel ? "" : "value-empty"}`}><span className="st-filter-title">Partner:</span> <span className={`st-selected-value `}><OverlayTrigger
                    overlay={
                      <Tooltip
                        id="tooltip-disabled-partner"
                        className="st-value-tooltip st-tooltip-btm"
                      >
                        <p>
                          {orderPartner ? orderPartner.map(val => val.label).join(", ") : ""}
                        </p>
                      </Tooltip>
                    }
                  ><span>{orderPartnerLabel}</span></OverlayTrigger></span></li>}
                </ul>
              </div>
              <RefreshPanel callback={getInitialListingData} />
            </div>
            <div
              className="st-help-container"
              onClick={(e) => setHelpModal(true)}
            >
              <span className="stat-help-icon">
                <span className="visually-hidden">Help icon</span>
              </span>
            </div>
            <OrdersGrid
              type={type}
              data={data}
              homeHealthListForModal={homeHealthListForModal}
              editModal={editModal}
              isModalOpen={isModalOpen}
              columns={HOME_HEALTH_COLUMNS}
              isClicked={isClicked}
              callback={(e) => {
                if (dateRef && dateRef.current) {
                  dateRef.current.setStartDate(today);
                  dateRef.current.setEndDate(new Date());
                }
                if (dateRef2 && dateRef2.current) {
                  dateRef2.current.setStartDate(today);
                  dateRef2.current.setEndDate(new Date());
                }
              }}
              orderType={orderType}
              totalOrderCount={totalOrderCount}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default HomeHealthList;
