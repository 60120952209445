import React, { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import Avatar from "react-avatar";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_DEVICE_TIMEZONE, HOMEHEALTH, ORDER_GRID_NAME, ROUTINEREDRAW, SEARCH, STATORDER, TIMEDDRAW, TYPE_HOMEHEALTH, TYPE_ROUTINE, TYPE_STAT, TYPE_TIMEDDRAW } from "../../app/utils/Constants";
import { clearGlobalSearchResponse, globalSearchErrorVal, setGlobalSearchError } from "../../features/GlobalSearch/globalSearchSlice";
import { filterList } from "../../features/LandingPage/AddUser/filterSlice";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { setHideFilter } from "../../features/StatOrder/statOrderReducer";

export const CommonHeaderRightSection = (props) => {
  const { logout, name, color, className, title, children = "", callbackToRefreshThePage } = props;
  const currentPath = window.location.pathname;
  const deviceTimezone = moment.tz.guess(true);
  const deviceAbbreviation = moment().tz(deviceTimezone).format("z")
  const dispatch = useDispatch();
  const history = useHistory();
  const isMounted = useRef(false);
  const isMountedForTz = useRef(false);
  const hideFilter = useSelector((state) => state.statGridData.hideFilter);
  const globalSearchError = useSelector(globalSearchErrorVal);
  const filter = useSelector(filterList);
  const { content: filterContent = {} } = filter || {};
  let { cloud_search_filter_list = {}, timezone_ids = [] } = filterContent;
  timezone_ids = timezone_ids ? [
    DEFAULT_DEVICE_TIMEZONE,
    ...timezone_ids
  ] : [DEFAULT_DEVICE_TIMEZONE]
  const entitiyList = Object.keys(cloud_search_filter_list).map(key => { return { label: cloud_search_filter_list[key], value: key } });

  const searchObj = window.localStorage.getItem(`globalSearch`)
    ? JSON.parse(window.localStorage.getItem(`globalSearch`)) || {}
    : {};

    const timezone = window.localStorage.getItem(`tz`)
    ? JSON.parse(window.localStorage.getItem(`tz`)) || DEFAULT_DEVICE_TIMEZONE
    : DEFAULT_DEVICE_TIMEZONE;

  const [searchObjState, setSearchObjState] = useState(
    searchObj
  );
  const { selectedEntities = [], text = "" } = searchObjState;

  const [preferredTimezone, setPreferredTimezone] = useState({...timezone, abbreviation: !timezone.id && timezone.abbreviation !== deviceAbbreviation ? deviceAbbreviation : timezone.abbreviation,
    timezone_name: !timezone.id && timezone.timezone_name !== deviceTimezone ? deviceTimezone : timezone.timezone_name
   });
  const [isOpen, setIsOpen] = useState(false);

  let permission =
    (window.localStorage.getItem("userDetail") &&
      JSON.parse(window.localStorage.getItem("userDetail")).permission &&
      JSON.parse(window.localStorage.getItem("userDetail")).permission) ||
    [];

  // globalsearch-permission
  const gloablSearchPermission = permission.filter(
    (perm) => perm.page === "cloudsearch"
  );
  const gloablSearchAccess =
    gloablSearchPermission.length === 1 ? gloablSearchPermission[0].access : [];
  const canViewGlobalSearch =
    gloablSearchAccess && gloablSearchAccess.find((item) => item === "search") === "search" ? true : false;



  useEffect(() => {
    if (isMounted.current) {
      console.log(entitiyList, 'entitiyList1', filter);
      if (filter && filter.status === "SUCCESS" && entitiyList && entitiyList.length && (!selectedEntities || !selectedEntities.length)) {
        // console.log(entitiyList, 'entitiyList2');
        updateValueInLocalStorage({ ...searchObjState, selectedEntities: entitiyList });
        setSearchObjState({ ...searchObjState, selectedEntities: entitiyList });
      }
    } else {
      isMounted.current = true;
    }

  }, [filter]);

  useEffect(() => {
    if (isMountedForTz.current) {
      callbackToRefreshThePage && callbackToRefreshThePage();
      if (!preferredTimezone.id && timezone.timezone_name !== preferredTimezone.timezone_name) {
        localStorage.setItem(`tz`, JSON.stringify(preferredTimezone))
      }
    } else {
      isMountedForTz.current = true;
    }
  }, [preferredTimezone])

  const handleFilterChange = (value) => {
    dispatch(setHideFilter(value));
    let type = currentPath === STATORDER ? TYPE_STAT : currentPath === ROUTINEREDRAW ? TYPE_ROUTINE : currentPath === TIMEDDRAW ? TYPE_TIMEDDRAW : currentPath === HOMEHEALTH ? TYPE_HOMEHEALTH : "";
    if (type) {
      var gridObj = document.getElementById(`${ORDER_GRID_NAME}-${type}`) && document.getElementById(`${ORDER_GRID_NAME}-${type}`).ej2_instances && document.getElementById(`${ORDER_GRID_NAME}-${type}`).ej2_instances[0];
      if (gridObj) {
        var groupDropArea = gridObj.element && gridObj.element.querySelector('.drag-column-wrapper');
        if (value) {
          gridObj.toolbarModule.element.classList.add('e-hide');
          groupDropArea.classList.add('e-hide');
        } else {
          gridObj.toolbarModule.element.classList.remove('e-hide');
          groupDropArea.classList.remove('e-hide');
        }
      }
    }
  }

  const updateValueInLocalStorage = (data) => {
    const searchObj = window.localStorage.getItem(`globalSearch`)
      ? JSON.parse(window.localStorage.getItem(`globalSearch`)) || {}
      : {};
    localStorage.setItem(
      "globalSearch",
      JSON.stringify({ ...searchObj, ...data })
    );
  }

  const handleChangeText = (e, clearSearchedResults = false) => {
    updateValueInLocalStorage({ ...searchObjState, text: e.target.value });
    setSearchObjState({ ...searchObjState, text: e.target.value });
    dispatch(setGlobalSearchError({ value: false, message: "" }));
    if (clearSearchedResults) {
      dispatch(clearGlobalSearchResponse());
      history.push(SEARCH);
    }
  }

  const handleChangeEntity = (e, entities = [], data) => {
    e.preventDefault();
    let val = [...entities];
    if (val.some(t => t.value == data.value)) {
      val = val.filter(t => t.value != data.value);
    } else {
      val.push(data);
    }
    updateValueInLocalStorage({ ...searchObjState, selectedEntities: val });
    setSearchObjState({ ...searchObjState, selectedEntities: val });
    dispatch(setGlobalSearchError({ value: false, message: "" }));
  }

  const handleSelectAll = (e) => {
    e.preventDefault();
    updateValueInLocalStorage({ ...searchObjState, selectedEntities: entitiyList });
    setSearchObjState({ ...searchObjState, selectedEntities: entitiyList });
    dispatch(setGlobalSearchError({ value: false, message: "" }));
  }

  const handleSelectNone = (e) => {
    e.preventDefault();
    updateValueInLocalStorage({ ...searchObjState, selectedEntities: [] });
    setSearchObjState({ ...searchObjState, selectedEntities: [] });
    dispatch(setGlobalSearchError({ value: false, message: "" }));
  }

  const handleChangeTimezone = (e, data) => {
    e.preventDefault();
    localStorage.setItem("tz", JSON.stringify(data));
    setPreferredTimezone(data);
    setIsOpen(false);
  }

  const handleSearchResults = () => {
    if (!text) {
      dispatch(setGlobalSearchError({ value: true, message: "Please enter a search term." }));
    } else if (!selectedEntities || !selectedEntities.length) {
      dispatch(setGlobalSearchError({ value: true, message: "Please select at least one filter option." }));
    } else {
      const searchedText = text;
      let path = `${SEARCH}?search_input=${encodeURIComponent(searchedText)}`;
      if (selectedEntities && selectedEntities.length) {
        path = `${path}&entity=${selectedEntities.map(ent => ent.value).join(",")}`
      }
      history.push(path);
    }
  }

  const handleKeyPress = (e) => {
    if (e.charCode == 13) {
      handleSearchResults();
    }
  };

  let customAbbreviation = "";
  if (preferredTimezone && preferredTimezone.id) {
    customAbbreviation = preferredTimezone.abbreviation;
  } else {
   timezone_ids && timezone_ids.forEach(tz => {
    if (tz.timezone_code === deviceTimezone) {
      customAbbreviation = tz.abbreviation;
    }
   });
   if (!customAbbreviation) {
    customAbbreviation = "ET"
   };
  }

  return (
    <div
      className={`ad-headertop-wrapper justify-content-between align-items-center scheduler-tabs-wrapper ad-headertop-new ${className || ""
        }`}
    >
      <div className='header-left'>
        <h1 className='headline4 pab-5'>{title}</h1>

        {(currentPath === STATORDER ||
          currentPath === ROUTINEREDRAW ||
          currentPath === TIMEDDRAW ||
          currentPath === HOMEHEALTH) && (
            /*<button className='so-hide-btn' onClick={() => dispatch(setHideFilter(!hideFilter))}>
              {hideFilter ? 'Show' : 'Hide'} Filter 
            	
            </button>*/
            <div
              className='icon-arrow-down-outlined'
              onClick={() => handleFilterChange(!hideFilter)}
              title='Show/Hide Filter'
            ></div>
          )}
      </div>
      {children}
      <div className='header-right d-flex align-items-center'>
        {canViewGlobalSearch && <div className='d-flex align-items-center position-relative'>
          <div className='position-relative me-2 pe-1'>
            <input
              type='text'
              className='search-box'
              placeholder='Search'
              value={text}
              onChange={(e) => handleChangeText(e)}
              id='global-search'
              onKeyPress={handleKeyPress}
            />
            <span className='close-icon' onClick={() => handleChangeText({ target: { value: "" } }, true)}>
              <span className='visually-hidden'>Close Icon</span>
            </span>
            <span className='search-icon' onClick={() => handleSearchResults()}>
              <span className='visually-hidden'>Search Icon</span>
            </span>
          </div>
          <Dropdown className='d-inline facility-filter filter gs-filter-wrapper'>
            <Dropdown.Toggle
              id='dropdown-autoclose-inside'
              className='filter-title sch-btn-transparent'
            >
              <span className='filter-icon'>
                <span className='visually-hidden'>Filter</span>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div>
                <div className='d-flex filter-reset-btn justify-content-between'>
                  <Button
                    className='primary-btn primary-btn-outline outline st-reset-all-btn'
                    onClick={handleSelectAll}
                  >
                    Select All
                  </Button>
                  <Button
                    className='primary-btn primary-btn-outline outline st-reset-all-btn'
                    onClick={handleSelectNone}
                  >
                    Select None
                  </Button>
                </div>
                {entitiyList &&
                  entitiyList.map((data, index) => {
                    const { label, value } = data;
                    return (
                      <div
                        key={value}
                        className='ml-weekday st-filter-checkbox-wrapper'
                      >
                        <label
                          className='ml-label-container st-label-container'
                          htmlFor={`search-${value}`}
                          onClick={(e) =>
                            handleChangeEntity(e, selectedEntities, data)
                          }
                        >
                          <input
                            style={{ display: "block !important" }}
                            type='checkbox'
                            id={`search-${value}`}
                            checked={
                              selectedEntities
                                ? selectedEntities.some(
                                  (st) => st.value == value
                                )
                                : false
                            }
                          />
                          <span className='ml-checkmark'></span>
                          <span className='st-checkbox-label'>{label}</span>
                        </label>
                      </div>
                    );
                  })}
              </div>
            </Dropdown.Menu>
          </Dropdown>
          {globalSearchError.value && (
            <p data-testid='error-text-id' className='error-message-text gs-error-msg'>
              {globalSearchError.message}
            </p>
          )}
        </div>}
        <div className='d-flex align-items-center position-relative gs-filter-wrapper aha-timezone-wrapper'>
          <Dropdown as={ButtonGroup} show={isOpen} onToggle={() => setIsOpen(!isOpen)}>
            <p className="timezone-name">{customAbbreviation}</p>
            <span className="timezone-divider"></span>
            <Dropdown.Toggle split id="dropdown-split-basic" className="sch-btn-transparent " />
            <Dropdown.Menu>
              <div>
                {timezone_ids &&
                  timezone_ids.map((data, index) => {
                    const { id, name, isVisible } = data;
                    return (isVisible &&
                      <div
                        key={id}
                        className='form-check aha-timezone-header'
                      >
                        <label
                           className="form-check-label"
                          htmlFor={`search-${id}`}
                          onClick={(e) =>
                            handleChangeTimezone(e, data)
                          }
                        >
                          <input
                              className="form-check-input"
                            type='radio'
                            id={`search-${id}`}
                            checked={preferredTimezone && preferredTimezone.id == id}
                          />
                          <span className='ml-checkmark'></span>
                          <span className='st-checkbox-label'>{name}</span>
                        </label>
                      </div>
                    );
                  })}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Dropdown
          data-testid='common-header-dropdown'
          className='aha-user-dropdown ms-3'
        >
          <Dropdown.Toggle
            data-testid='common-header-toggle'
            id='dropdown-basic'
            className='profile-dropdown'
          >
            <span className='visually-hidden'>profile dropdown</span>
            <Avatar
              color={color ? `#${color}` : "#333745"}
              name={name}
              maxInitials={2}
              size={40}
              round='50px'
            />
          </Dropdown.Toggle>
          <Dropdown.Menu
            data-testid='common-header-dropdownmenu'
            className='aha-logout-dropmenu'
          >
            <Dropdown.Item
              data-testid='common-header-logout'
              onClick={() => logout()}
            >
              Log Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
