import React from "react";
import {
  Aggregate,
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Group,
  Inject,
  Page,
  Sort,
  Toolbar,
  Reorder,
  ExcelExport,
  Resize
} from "@syncfusion/ej2-react-grids";
import { DEFAULT_ORDER_SIZE, ORDER_GRID_NAME, TYPE_HOMEHEALTH } from "../../app/utils/Constants";

export const sortComparer = (reference, comparer) => {
  const parseDate = (dateStr) => {
    if (dateStr === "-") {
      return null;
    }
    const cleanDateStr = dateStr.replace(/\s+[A-Z]{2,4}$/, ''); // Remove space followed by 2 to 4 uppercase letters (timezone)

    // Parse the cleaned-up date string into a Date object
    return cleanDateStr.length > 0 ? new Date(cleanDateStr) : null;
  };

  const newRef = parseDate(reference);
  const newComparer = parseDate(comparer);

  // custom sort comparer
  if (newRef === null && newComparer === null) return 0; // Both dates are "-"
  if (newRef === null) return -1; // Sort "-" before actual dates
  if (newComparer === null) return 1; // Sort "-" after actual dates
  if (newRef < newComparer) return -1;
  if (newRef > newComparer) return 1;
  return 0;
};

const PAGE_SIZE = 100;
class OrdersGrid extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toolbar = ["Search", "ExcelExport", "ColumnChooser"];
    this.toolbarClick = (args) => {
      const { type } = this.props;
      if (
        this[`${ORDER_GRID_NAME}-${type}`] &&
        args?.item?.id === `${ORDER_GRID_NAME}-${this.props.type}_excelexport`
      ) {
        const excelExportProperties = {
          enableFilter: true,
          includeHiddenColumn: true,
          exportType: "All",
        };
        this[`${ORDER_GRID_NAME}-${type}`].excelExport(excelExportProperties);
      }
    };
    this.sortingOptions = {
      columns: [{ field: "OrderCreated", direction: "Descending" }]
    };
    this[`groupedCount-${this.props.type}`] = 0;
    this.gridElement = null; // For the main grid
    this[`gridColumns-${this.props.type}`] = [];
    this.state = {
      ordersRowCount: this.props.data.length
    }
  }
  componentDidMount() {
    // this.gridElement = this[`${ORDER_GRID_NAME}-${this.props.type}`].element;
    // this.gridContentElement = this.gridElement.querySelector('.e-gridcontent');
    // if (this.gridContentElement) {
    //   this.gridContentElement.addEventListener('scroll', this.handleScroll);
    //   this.handleScroll();
    // }
    this.updatePageSize();
  }

  handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = this.gridContentElement;

    const isScrollable = scrollHeight > clientHeight;
    const notAtBottom = scrollTop + clientHeight < scrollHeight;

    this.setState({
      showButton: isScrollable && notAtBottom,
    });
  };

  componentWillUnmount() {
    if (this[`${ORDER_GRID_NAME}-${this.props.type}`]) {
      this[`${ORDER_GRID_NAME}-${this.props.type}`].destroy();
    }
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.isClicked !== this.props.isClicked && this.props.isClicked) {
      this.clearFilter();
    }
    if (prevProps.isModalOpen !== this.props.isModalOpen && !this.props.isModalOpen) {
      this.clearSelectedRow();
    }
  };
  updatePageSize = () => {
    const { type } = this.props;
    let persistedData = this[`${ORDER_GRID_NAME}-${type}`].getPersistData()
      ? JSON.parse(this[`${ORDER_GRID_NAME}-${type}`].getPersistData())
      : {};
    var value = window.localStorage.getItem(`grid${ORDER_GRID_NAME}-${type}`); //"gridGrid" is component name + component id.
    var model = value ? { ...JSON.parse(value) } : {};
    model["pageSettings"] = model["pageSettings"] ? {
      ...model["pageSettings"],
      pageSize: PAGE_SIZE
    } : { pageSize: PAGE_SIZE };
    this[`${ORDER_GRID_NAME}-${type}`].setProperties(
      JSON.stringify({ ...persistedData, ...model })
    );
    window.localStorage.setItem(
      `grid${ORDER_GRID_NAME}-${type}`,
      JSON.stringify(model)
    );
    let grid = this[`${ORDER_GRID_NAME}-${type}`];
    grid.pageSettings.pageSize = PAGE_SIZE;
  };
  clickHandler = () => {
    const { type } = this.props;
    this[`${ORDER_GRID_NAME}-${type}`].searchSettings.key = "";
    const element = this[`${ORDER_GRID_NAME}-${type}`].element.querySelector(
      ".e-input-group.e-search .e-input"
    );
    element.value = "";
    var grid2 = this[`${ORDER_GRID_NAME}-${type}`];
    if (
      grid2 &&
      grid2.element &&
      grid2.element
        .getElementsByClassName("e-search")[0]
        .classList.contains("clear")
    ) {
      const parent = document.getElementById(
        `${ORDER_GRID_NAME}-${this.props.type}clear`
      );
      parent && parent.parentNode && parent.parentNode.removeChild(parent);
      grid2.element
        .getElementsByClassName("clear")[0]
        .classList.remove("clear");
      grid2.element
        .getElementsByClassName("e-search-icon")[0]
        .classList.remove("hide");
    }
  };
  created = (args) => {
    const { type } = this.props;
    var gridElement = this[`${ORDER_GRID_NAME}-${type}`] && this[`${ORDER_GRID_NAME}-${type}`].element;

    if (this[`${ORDER_GRID_NAME}-${type}`]) {
      this[`${ORDER_GRID_NAME}-${type}`].searchModule.hasNonNumericCharacters = function (searchKey) {
        return true;
      };
    }

    if (gridElement) {
      var grid2 = this[`${ORDER_GRID_NAME}-${type}`];
      var emptyRow = grid2 && grid2.element.querySelector(".e-emptyrow");
      if (emptyRow) {
        emptyRow.cells[0].innerText = "No records to display.";
      }

      document &&
        document.getElementById(gridElement.id + "_searchbar") &&
        document
          .getElementById(gridElement.id + "_searchbar")
          .addEventListener("keyup", (e) => {
            this.setDebounce(e.target.value);
          });

      // wrapper for drop area
      let dropArea = gridElement
        ? gridElement.getElementsByClassName("e-groupdroparea")
        : [];
      let dropWrapper = gridElement
        ? gridElement.getElementsByClassName("drag-column-wrapper")
        : [];
      if (dropArea.length > 0 && !dropWrapper.length) {
        dropArea = dropArea[0];

        let divWrapper = document.createElement("div");
        divWrapper.className = "drag-column-wrapper";

        dropArea.parentNode.insertBefore(divWrapper, dropArea);
        divWrapper.appendChild(dropArea);
      }

      // add clear button and info icon in toolbar
      let toolbarContainer = gridElement
        ? gridElement.getElementsByClassName("e-toolbar-left")
        : [];

      if (toolbarContainer.length > 0) {
        toolbarContainer = toolbarContainer[0];

        // Find the Excel Export div
        let excelExportDiv = toolbarContainer.querySelector('.e-toolbar-item[title="Excel Export"]');

        if (excelExportDiv) {
          excelExportDiv.classList.add('client-export-class');
          let buttonTextSpan = excelExportDiv.querySelector('.e-tbar-btn-text');
          if (buttonTextSpan) {
            buttonTextSpan.innerText = 'Export Filtered Selection';
          }
        }

        // Check if the info icon div already exists
        let infoIconDiv = toolbarContainer.querySelector('.info-icon-tooltip');
        if (!infoIconDiv && excelExportDiv) {
          // Create the info icon div
          infoIconDiv = document.createElement('div');
          infoIconDiv.className = "e-toolbar-item info-icon-tooltip st-value-tooltip";

          const infoIcon = document.createElement('span');
          infoIcon.className = "info-icon";
          infoIcon.title = "This exports only the orders visible on the page.";
          infoIconDiv.appendChild(infoIcon);

          // insert the info icon div after the Excel Export div
          excelExportDiv.parentNode.insertBefore(infoIconDiv, excelExportDiv.nextSibling);
        }

        // The Clear Filter button
        let clearButton = toolbarContainer.querySelector('.clear-internal-grid-button');
        if (!clearButton) {
          let divWrapper = document.createElement("div");
          divWrapper.className = "e-toolbar-item clear-internal-grid-button filter-reset-btn";
          divWrapper.title = "Clear Button";

          const newButton = document.createElement('button');
          newButton.title = `This resets Grid's internal filters (searching, column-level-filters, grouping, column selection).`;
          newButton.className = "primary-btn primary-btn-outline outline st-reset-all-btn btn btn-primary";
          newButton.onclick = function () {
            this.clearFilter(false);
          }.bind(this);

          const spanIcon = document.createElement('span');
          spanIcon.className = "reset-all reset-grid-filter pe-1";
          spanIcon.style.paddingTop = "1px";
          newButton.appendChild(spanIcon);

          const buttonText = document.createTextNode('Reset Grid Filters');
          newButton.appendChild(buttonText);

          divWrapper.appendChild(newButton);

          toolbarContainer.appendChild(divWrapper);
        }
      }

      if (
        document.getElementById(`${gridElement.id}_searchbar`) &&
        document.getElementById(`${gridElement.id}_searchbar`).value
      ) {
        document.getElementsByClassName("e-search-icon") &&
          document.getElementsByClassName("e-search-icon")[0] &&
          document
            .getElementsByClassName("e-search-icon")[0]
            .classList.add("hide");
      }
    }
  };
  setDebounce = this.debounce((value) => {
    // perform programmatic search
    this[`${ORDER_GRID_NAME}-${this.props.type}`].search(value);
  }, 1000); // set the debounce time, increase timeout value as per your need to avoid you mentioned flicker scenario.
  debounce(func, wait) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        func.apply(context, args);
      }, wait);
    };
  }
  dataBound = (args) => {
    const { type } = this.props;
    const gridElement = this[`${ORDER_GRID_NAME}-${type}`] && this[`${ORDER_GRID_NAME}-${type}`].element;
    if (gridElement) {
      let dropArea = gridElement
        ? gridElement.getElementsByClassName("e-groupdroparea")
        : [];
      let dropWrapper = gridElement
        ? gridElement.getElementsByClassName("drag-column-wrapper")
        : [];
      if (dropArea.length > 0 && !dropWrapper.length) {
        dropArea = dropArea[0];

        let divWrapper = document.createElement("div");
        divWrapper.className = "drag-column-wrapper";

        dropArea.parentNode.insertBefore(divWrapper, dropArea);
        divWrapper.appendChild(dropArea);
      }
    }
    var grid2 = this[`${ORDER_GRID_NAME}-${type}`];
    document &&
      grid2 &&
      document.getElementById(grid2.element.id + "_searchbar") &&
      document
        .getElementById(grid2.element.id + "_searchbar")
        .addEventListener("keyup", (event) => {
          if (!event.target.value) {
            if (
              grid2 &&
              grid2.element &&
              grid2.element.getElementsByClassName("e-search") &&
              grid2.element.getElementsByClassName("e-search")[0] &&
              grid2.element
                .getElementsByClassName("e-search")[0]
                .classList.contains("clear")
            ) {
              const span = document.getElementsByClassName("e-clear-icon");
              while (span && span.length > 0 && span[0]) {
                grid2.element
                  .getElementsByClassName("clear")[0]
                  .removeChild(span[0]);
              }
              grid2.element
                .getElementsByClassName("clear")[0]
                .classList.remove("clear");
              grid2.element
                .getElementsByClassName("e-search-icon")[0]
                .classList.remove("hide");
            }
          } else {
            //  checks whether the cancel icon is already present or not
            if (
              grid2 &&
              grid2.element &&
              !grid2.element
                .getElementsByClassName("e-search")[0]
                .classList.contains("clear")
            ) {
              var gridElement = this[`${ORDER_GRID_NAME}-${type}`].element;
              if (
                grid2.element.getElementsByClassName(`e-sicon`) &&
                grid2.element.getElementsByClassName(`e-sicon`)[0]
              ) {
                grid2.element
                  .getElementsByClassName(`e-sicon`)[0]
                  .classList.add("hide");
              }
              var span = document.createElement("span");
              span.className = "e-clear-icon";
              span.id = gridElement.id + "clear";
              span.onclick = this.clickHandler.bind(this);
              grid2.element
                .getElementsByClassName("e-search")[0]
                .appendChild(span);
              grid2.element
                .getElementsByClassName("e-search")[0]
                .classList.add("clear");
              grid2.element
                .getElementsByClassName("e-search-icon")[0]
                .classList.add("hide");
            }
          }
        });

    var emptyRow = grid2 && grid2.element.querySelector(".e-emptyrow");
    if (emptyRow) {
      emptyRow.cells[0].innerText = "No records to display";
    }
    let grid = this[`${ORDER_GRID_NAME}-${type}`];
    if (grid) {
      if (grid.groupSettings.columns.length && !this[`groupedCount-${type}`]) {
        let totalGroupedCount = 0;
        grid.currentViewData.forEach(cData => {
          totalGroupedCount += cData.count;
        });
        this[`groupedCount-${type}`] = totalGroupedCount; // set group row count when group or ungroup column.
      }
      let rowCount = grid.groupSettings.columns.length ? grid.currentViewData?.records?.length : grid.currentViewData.length;
      this.setState({
        ordersRowCount: rowCount
      })
    }
    // let cloned = grid.addOnPersist;
    // grid.addOnPersist = function (key) {
    //     key = key.filter((item) => item !== "columns");
    //     return cloned.call(this, key);
    // };
  };
  handleAction = (args) => {
    const { type } = this.props;
    if (args.requestType == "filterafteropen") {
      let grid =
        this[`${ORDER_GRID_NAME}-${type}`].element.getElementsByClassName(
          `e-popup-open`
        );
      if (grid && grid.length) {
        grid[0].ej2_instances[0].header = args.filterModel.options.displayName;
      }
    }
    if (args.requestType == 'filterchoicerequest') {
      // hide 'Add current selection to filter' option
      var addCurrent = args.filterModel.dlg.querySelector('.e-add-current');

      if (addCurrent) {
        addCurrent.closest('.e-ftrchk').style.display = 'none';
      }
    }
    if (args.requestType === 'searching') {
      let grid = this[`${ORDER_GRID_NAME}-${type}`];
      grid.toolbarModule.enableItems([grid.element.id + '_searchbar'], true);
    }
  };
  actionBegin = (args) => {
    const { type } = this.props;
    if (
      args.requestType === "filterchoicerequest" ||
      args.requestType === "filtersearchbegin"
    ) {
      args.filterChoiceCount = 100000;
    }
    if (args.requestType === 'grouping' || args.requestType === 'ungrouping') {
      this[`groupedCount-${type}`] = 0; // reset grouping count when group or ungroup column.
    }
    if (args.requestType === 'searching') {
      let grid = this[`${ORDER_GRID_NAME}-${type}`];
      grid.toolbarModule.enableItems([grid.element.id + '_searchbar'], false);
    }
  };
  clearFilter = (shouldCallCallback = true) => {
    const { type, callback } = this.props;
    let grid = this[`${ORDER_GRID_NAME}-${type}`];
    if (grid) {
      shouldCallCallback && callback();
      grid.clearFiltering();
      grid.clearSorting();
      grid.sortColumn(this.sortingOptions.columns[0].field, this.sortingOptions.columns[0].direction, true);
      grid.searchSettings.key = "";
      setTimeout(() => {
        grid.clearGrouping();
      }, 1);
    }
  };
  rowSelected = (e) => {
    const { homeHealthListForModal = [], type } = this.props;
    if (e.isInteracted) {
      if (this.props.type === "user") {
        this.props.editModal(e);
      } else if (type === TYPE_HOMEHEALTH) {
        const dataId = e.data && e.data.id;
        let data = {};
        homeHealthListForModal.forEach(dd => {
          if (dd.id == dataId) {
            data = dd;
          }
        })
        this.props.editModal(true, ((data && Object.keys(data).length > 0) ? { data: data } : e), "view");
      } else {
        this.props.editModal(true, e, "view");
      }
    }
  };
  clearSelectedRow = () => {
    let grid = this[`${ORDER_GRID_NAME}-${this.props.type}`];
    grid && grid.clearRowSelection()
  }

  scrollToBottom = () => {
    let gridContentDiv = this[`${ORDER_GRID_NAME}-${this.props.type}`].element.getElementsByClassName('e-content')[0];
    gridContentDiv && gridContentDiv.scrollTo(0, gridContentDiv.scrollHeight);
  };

  render() {
    let {
      data = [],
      columns = [],
      type,
      totalOrderCount
    } = this.props;
    const { ordersRowCount } = this.state;
    return (
      <div className={"ad-grid-wrapper grid-common-wrapper"}>
        <GridComponent
          ref={(comp) => {
            this[`${ORDER_GRID_NAME}-${type}`] = comp;
          }}
          id={`${ORDER_GRID_NAME}-` + type}
          dataSource={data}
          pageSettings={{ pageSize: PAGE_SIZE }}
          toolbar={this.toolbar}
          toolbarClick={this.toolbarClick}
          created={this.created}
          dataBound={this.dataBound}
          actionComplete={this.handleAction}
          actionBegin={this.actionBegin}
          rowSelected={this.rowSelected}
          sortSettings={this.sortingOptions}
          groupSettings={{ showDropArea: true }}
          searchSettings={{ operator: 'contains' }}
          filterSettings={{ type: "CheckBox", enableCaseSensitivity: false }}
          allowPaging={true}
          allowSorting={true}
          allowMultiSorting={true}
          showColumnChooser={true}
          allowFiltering={true}
          allowExcelExport={true}
          allowResizing={true}
          allowGrouping={true}
          allowReordering={true}
          enablePersistence={true}
          delayUpdate
        >
          <Inject
            services={[ExcelExport,
              Reorder,
              Aggregate,
              Group,
              Sort,
              Filter,
              Aggregate,
              Toolbar,
              ColumnChooser,
              Resize,
              Page]}
          />

          <ColumnsDirective>
            {columns.map((column, index) => {
              return (
                <ColumnDirective
                  field={column.field}
                  headerText={column.headerText}
                  width={column.width}
                  template={column.template || null}
                  visible={column.visible}
                  key={index}
                  filter={{ operators: "contains" }}
                  sortComparer={column.type === "Date" ? sortComparer : undefined}
                />
              );
            })}
          </ColumnsDirective>
        </GridComponent>
        {/* {true && <div className="back-to-top-wrapper">
          <button
            className="back-to-top justify-content-center align-items-center"
            onClick={this.scrollToBottom}
          >
            <span className="mob-back"></span>
          </button>
        </div>} */}
        <div className="items-count-wrap">{`Total: ${totalOrderCount || 0} ${totalOrderCount > 1 ? "orders" : "order"}`}</div>
      </div>
    );
  }
}
export default OrdersGrid;
