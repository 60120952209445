/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ACCESS_STATUS,
  CONTACT,
  DESKTOP_APP_VERSION,
  FORGOT_PASSWORD,
  FORGOT_USER,
  GOOGLE_AUTH,
  SET_PASSWORD,
  TROUBLE_LOGIN,
  VERIFY_DEVICE,
} from "../../../app/utils/Constants";
import Logo from "../../../components/layouts/Logo";
import { accountList, postLogin, loading } from "./accountSlice";
import Toast from "../../../components/layouts/ToastMessage";
import { appConfig } from "../../Chat/Config";
import jwt_decode from "jwt-decode";
// import { useNavigate } from "react-router-dom";
export default function Login(props) {
  // const navigate = useNavigate();
  const accounts = useSelector(accountList);
  const loader = useSelector(loading);
  const dispatch = useDispatch();
  const [toast, setToast] = React.useState(false);
  const [resetBlock, setResetBlock] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState("");
  const [error, setError] = React.useState(false);
  const [data, setData] = React.useState("");
  const [visiblePassword, setVisiblePassword] = React.useState(false);
  // const usernameValid =
  //   data && data.username.search(USERNAME) >= 0 ? true : false;
  document.documentElement.classList.add("login-flow");

  React.useEffect(() => {
    const secret_code =
      accounts && accounts.content && accounts.content.secret_code;
    const session_id =
      accounts && accounts.content && accounts.content.session_id;
    const challenge_name =
      accounts && accounts.content && accounts.content.challenge_name;
    if (
      accounts &&
      !accounts.error &&
      accounts &&
      accounts.content &&
      accounts.content.challenge_name &&
      accounts.content.challenge_name === "NEW_PASSWORD_REQUIRED"
    ) {
      props.history.push(SET_PASSWORD, {
        username: data && data.username,
        session_id: session_id,
        challenge_name: challenge_name,
      });
    } else if (
      accounts &&
      !accounts.error &&
      accounts &&
      accounts.content &&
      accounts.content.challenge_name &&
      accounts.content.challenge_name === "VERIFY_DEVICE"
    ) {
      props.history.push({
        pathname: GOOGLE_AUTH,
        state: {
          challenge_name: challenge_name,
          secret_code: secret_code,
          session_id: session_id,
        },
      });
    } else if (
      accounts &&
      !accounts.error &&
      accounts &&
      accounts.content &&
      accounts.content.challenge_name &&
      accounts.content.challenge_name === "SOFTWARE_TOKEN_MFA"
    ) {
      props.history.push({
        pathname: VERIFY_DEVICE,
        state: {
          username: data && data.username,
          challenge_name: challenge_name,
          secret_code: secret_code,
          session_id: session_id,
        },
      });
    } else if (
      accounts &&
      accounts.content &&
      accounts.content.access_token &&
      accounts.status === "SUCCESS"
    ) {
      const token =
        accounts && accounts.content && accounts.content.access_token;
      const refreshToken =
        accounts && accounts.content && accounts.content.refresh_token;
      const idToken = accounts && accounts.content && accounts.content.id_token;
      const deviceInfo = (accounts && accounts.content && accounts.content.device_info) || "";
      const username = data.username.toLowerCase();
      localStorage.setItem(
        "userData",
        JSON.stringify(accounts && accounts.content)
      );
      localStorage.setItem("username", username);
      localStorage.setItem("app_v", DESKTOP_APP_VERSION);
      if (deviceInfo) {
        const decodedData = jwt_decode(deviceInfo) || {};
        localStorage.setItem(
          `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.${username}.deviceKey`,
          decodedData.k
        );
      } else if (localStorage.getItem("device_info")) {
        const decodedData = jwt_decode(localStorage.getItem("device_info")) || {};
        localStorage.setItem(
          `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.${username}.deviceKey`,
          decodedData.k
        );
      }
      localStorage.setItem(
        `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.${username}.accessToken`,
        token
      );
      localStorage.setItem(
        `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.${username}.idToken`,
        idToken
      );
      localStorage.setItem(
        `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.LastAuthUser`,
        username
      );
      localStorage.setItem(
        `CognitoIdentityServiceProvider.${appConfig.cognitoAppClientId}.${username}.refreshToken`,
        refreshToken
      );
      props.history.push(ACCESS_STATUS);
    } else if (accounts && accounts && accounts.status === "ERROR") {
      if (
        accounts.message_code &&
        accounts.message_code === "SIGN_IN_ATTEMPT_EXCEEDED"
      ) {
        setError(true);
        setErrMsg(accounts.message);
        setToast(true);
        setResetBlock(true);
        // setTimeout(() => {
        //   window.location.href = FORGOT_PASSWORD;
        // }, 2000);
      } else if (
        accounts.message_code &&
        accounts.message_code === "USER_ACCOUNT_LOCKED_BY_ADMIN"
      ) {
        setError(true);
        setErrMsg(accounts.message);
        setToast(false);
        setTimeout(() => {
          props.history.push({
            pathname: CONTACT,
            state: {
              message: "Your account has been locked by admin",
            },
          });
        }, 2000);
      } else if (
        accounts.message_code &&
        accounts.message_code === "USER_ACCOUNT_LOCKED_LIMIT_EXCEEDED"
      ) {
        setError(true);
        setErrMsg(accounts.message);
        setToast(false);
        setTimeout(() => {
          props.history.push(CONTACT);
        }, 2000);
      } else {
        let err = `${accounts.message}, ${
          accounts && accounts.content && accounts.content.message_2
        }`;

        setError(true);
        setErrMsg(
          accounts && accounts.content && accounts.content.message_2
            ? err
            : accounts.message
        );
        setToast(false);
      }
    }
  }, [accounts]);
  const toastHandler = () => {
    setToast(true);
  };
  const handleLogin = () => {
    if (data && data.username && data.password) {
      let cred = [];
      if (
        window.localStorage.getItem("device_info") &&
        JSON.parse(window.localStorage.getItem("device_info"))
      ) {
        cred = {
          username: data && data.username,
          password: data && data.password,
          device_info:
            window.localStorage.getItem("device_info") &&
            JSON.parse(window.localStorage.getItem("device_info")),
        };
      } else {
        cred = {
          username: data && data.username,
          password: data && data.password,
        };
      }
      dispatch(postLogin(cred));
      const username = data.username.toLowerCase();
      let scheduleFilter = window.localStorage.getItem(`${username}.scheduleFilter`)
      ? JSON.parse(window.localStorage.getItem(`${username}.scheduleFilter`))
      : {};

      let {selectedDate = ""} = scheduleFilter;

      selectedDate = new Date(selectedDate);
      selectedDate.setHours(0,0,0,0);

      let todaysDate = new Date();
      todaysDate.setHours(0,0,0,0);
      if (selectedDate) {
        localStorage.setItem(`${username}.scheduleFilter`,
        JSON.stringify({ ...scheduleFilter, selectedDate: new Date() }))
      }

      // hh schedule
      let homeHealthScheduleFilter = window.localStorage.getItem(`${username}.homeHealthScheduleFilter`)
      ? JSON.parse(window.localStorage.getItem(`${username}.homeHealthScheduleFilter`))
      : {};

      let {selectedDate: hhSelectedDate = ""} = homeHealthScheduleFilter;

      hhSelectedDate = new Date(hhSelectedDate);
      hhSelectedDate.setHours(0,0,0,0);

      todaysDate.setHours(0,0,0,0);
      if (hhSelectedDate) {
        localStorage.setItem(`${username}.homeHealthScheduleFilter`,
        JSON.stringify({ ...homeHealthScheduleFilter, selectedDate: new Date() }))
      }
    }
  };
  const handleKeyPress = (e) => {
    if (e.charCode == 13) {
      handleLogin();
    }
  };
  return (
    <React.Fragment>
      <Logo />
      <h1 className="mb-10 roboto-regular login-title visually-hidden">
        Login Page
      </h1>
      <div>
        <form className="aha-login-wrapper">
          <div className="aha-form-group mb-10 text-start">
            <label className="mb-0 roboto-medium form-label" htmlFor="username">
              Username
            </label>
            <input
              type="text"
              className={
                "form-control " +
                (!error
                  ? ""
                  : data && data.username.length > 0
                  ? resetBlock
                    ? "disabled"
                    : "invalid"
                  : "invalid")
              }
              id="username"
              onChange={(e) => {
                setData({ ...data, username: e.target.value });
              }}
              onKeyPress={handleKeyPress}
              disabled={resetBlock}
            />
          </div>
          <div className="aha-form-group mb-20 text-start position-relative">
            <label
              className="mb-0 roboto-medium form-label"
              htmlFor="login-password"
            >
              Password
            </label>
            <input
              type={visiblePassword ? "text" : "password"}
              className={
                "form-control " +
                (!error
                  ? ""
                  : data && data.username.length > 0
                  ? resetBlock
                    ? "disabled"
                    : "invalid"
                  : "invalid")
              }
              id="login-password"
              onChange={(e) => {
                setData({ ...data, password: e.target.value });
              }}
              onKeyPress={handleKeyPress}
              disabled={resetBlock}
            />
            {!resetBlock && (
              <span
                className={"view-pwd-icon " + (visiblePassword ? "active" : "")}
                onClick={() => {
                  setVisiblePassword(!visiblePassword);
                }}
              >
                <span className="visually-hidden">Eye Icon</span>
              </span>
            )}
          </div>
          {resetBlock ? (
            <div>
              <p className="red-color mb-20 roboto-regular font-size-12 text-start input-validation-msg">
                {errMsg}
              </p>
              <form className="aha-login-wrapper">
                <button
                  className="primary-btn mb-15"
                  type="submit"
                  onClick={() => {
                    props.history.push(FORGOT_USER);
                  }}
                >
                  <span>Forgot Username</span>
                  <span className="loader">
                    <span className="visually-hidden">loader icon</span>
                  </span>
                </button>
                <div className="separator-wrapper mb-15">
                  <span className="position-relative d-block roboto-bold font-size-12">
                    or
                  </span>
                </div>
                <button
                  className="primary-btn mb-10"
                  type="submit"
                  onClick={() => {
                    props.history.push(FORGOT_PASSWORD);
                    document.documentElement.classList.remove("login-flow");
                  }}
                >
                  <span>Forgot Password</span>
                  <span className="loader">
                    <span className="visually-hidden">Loader Icon</span>
                  </span>
                </button>
              </form>
            </div>
          ) : (
            <button
              disabled={data && data.username && data.password ? false : true}
              className="primary-btn mb-10"
              type="button"
              onClick={() => {
                handleLogin();
              }}
            >
              {loader ? "" : <span>Login</span>}
              <span className={"loader " + (loader ? "d-block" : "")}>
                <span className="visually-hidden">loader icon</span>
              </span>
            </button>
          )}
        </form>
        {!resetBlock && (
          <div className="aha-link-wrapper">
            <a href={TROUBLE_LOGIN}>Recover Username/Password?</a>
          </div>
        )}
      </div>
      {error && (
        <Toast
          message={errMsg}
          type="error"
          setToast={toastHandler}
          toast={toast}
        />
      )}
    </React.Fragment>
  );
}
