
window.customStatus = function (args) {
    const { status_color_code = {}, OrderStatus } = args;
    const color = status_color_code
        ? status_color_code.color_code
            ? status_color_code.color_code
            : ""
        : "";
    return `<div class="stat-order-status"
        style="background: ${color};"
      >
        <p class="title">
          <span
            class="${`icon ${OrderStatus
                ? OrderStatus.toLowerCase()
                : ""
        }`}"
          ></span>
          ${OrderStatus}
        </p>
      </div>`;
};

export const HOME_HEALTH_COLUMNS = [
    {
        field: "OrderStatus",
        headerText: "Status",
        width: "210",
        template: "<div>${customStatus(data)}</div>",
        disableHtmlEncode: false,
    },
    {
        field: "OrderNumber",
        headerText: "Order #",
        width: "210",
        disableHtmlEncode: false,
    },
    {
        field: "PartnerOrderID",
        headerText: "Partner Order ID",
        width: "210",
        disableHtmlEncode: false,
    },
    {
        field: "OrderCreated",
        headerText: "Order Created",
        width: "210",
        disableHtmlEncode: false,
        type: "Date"
    },
    {
        field: "OrderCreationMethod",
        headerText: "Order Creation Method",
        width: "210",
        disableHtmlEncode: false,
        visible: true,
    },
    {
        field: "OrderCreationUser",
        headerText: "Order Creation User",
        width: "210",
        disableHtmlEncode: false,
        visible: false,
    },
    {
        field: "CheckoutStatus",
        headerText: "Checkout Status",
        width: "210",
        disableHtmlEncode: false,
        visible: true,
    },
    {
        field: "ScheduledDate",
        headerText: "Scheduled Date",
        width: "210",
        disableHtmlEncode: false,
        type: "Date"
    },
    {
        field: "ScheduledStartTime",
        headerText: "Scheduled Start Time",
        width: "210",
        disableHtmlEncode: false,
    },
    {
        field: "ScheduledEndTime",
        headerText: "Scheduled End Time",
        width: "210",
        disableHtmlEncode: false,
    },
    {
        field: "Region",
        headerText: "Region",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "Coordinator",
        headerText: "Coordinator",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "CoordinatorEmpID",
        headerText: "Coordinator Emp ID",
        width: "230",
        disableHtmlEncode: false
    },
    {
        field: "Phlebotomist",
        headerText: "Phlebotomist",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "PhlebEmpID",
        headerText: "Phleb Emp ID",
        width: "230",
        disableHtmlEncode: false
    },
    {
        field: "PatientName",
        headerText: "Patient Name",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "PatientPhoneNumber",
        headerText: "Patient Phone Number",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "PatientRequestedDateTime",
        headerText: "Patient Requested Date/Time",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "PatientDOB",
        headerText: "Patient DOB",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
        type: "Date"
    },
    {
        field: "PatientBiologicalSex",
        headerText: "Patient Biological Sex",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "PatientStreetAddress",
        headerText: "Patient Street Address",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "City",
        headerText: "City",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "State",
        headerText: "State",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "Zip",
        headerText: "Zip",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "DropOffType",
        headerText: "Drop Off Type",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "DropLocationName",
        headerText: "Drop Location Name",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "PartnerName",
        headerText: "Partner Name",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "PartnerID",
        headerText: "Partner ID",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "CancellationReason",
        headerText: "Cancellation Reason",
        width: "255",
        disableHtmlEncode: false,
    },
    {
        field: "Notes",
        headerText: "Notes",
        width: "255",
        disableHtmlEncode: false,
    },

    {
        field: "LastUpdatedDateTime",
        headerText: "Last Updated DateTime",
        width: "255",
        visible: true,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "NateraCancellationInitiator",
        headerText: "Natera Cancellation Initiator",
        width: "255",
        visible: true,
        filter: { operators: "contains" }
    },
    {
        field: "OrderingPhysician",
        headerText: "Ordering Physician",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "AccountNumber",
        headerText: "Account Number",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    }, {
        field: "AccountName",
        headerText: "Account Name",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "ServiceType",
        headerText: "Service Type",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "PatientEmailAddress",
        headerText: "Patient Email Address",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    }, {
        field: "PatientAddressType",
        headerText: "Patient Address Type",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    }, {
        field: "PatientConfirmation",
        headerText: "Patient Confirmation",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    }, {
        field: "PatienthasPartnerkit",
        headerText: "Patient has Partner kit",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    }, {
        field: "SMSOptin",
        headerText: "SMS Opt in",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    }, {
        field: "AccessionInformation",
        headerText: "Accession Information",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    }, {
        field: "NotifiedPerson",
        headerText: "Notified Person",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "ScheduledTimezone",
        headerText: "Scheduled Timezone",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "TestCode",
        headerText: "Test Code(s)",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "SupervisorName",
        headerText: "Supervisor Name",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "CheckIn",
        headerText: "Check-In Date/Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "CheckOut",
        headerText: "Check-Out Date/Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "DropOff",
        headerText: "Drop-Off Date/Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "TestDescription",
        headerText: "Test Description(s)",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "VisitType",
        headerText: "Visit Type",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "PartnerKitRequired",
        headerText: "Partner Kit Required",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "CareDxDropSite",
        headerText: "CareDx Drop Site",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "ResultFinalizedDate",
        headerText: "Result Finalized Date",
        width: "255",
        visible: false,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "ResultFinalizedTime",
        headerText: "Result Finalized Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "ValidatedByMapboxString",
        headerText: "Validated by Google",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    }
];

export const STAT_COLUMNS = [
    {
        field: "OrderStatus",
        headerText: "Status",
        width: "210",
        template: '<div>${customStatus(data)}</div>',
        disableHtmlEncode: false,
    },
    {
        field: "OrderNumber",
        headerText: "Order #",
        width: "210",
        disableHtmlEncode: false,
    },
    {
        field: "OrderCreated",
        headerText: "Order Created",
        width: "210",
        disableHtmlEncode: false,
        type: "Date"
    },
    {
        field: "OrderCreationMethod",
        headerText: "Order Creation Method",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "OrderType",
        headerText: "Order Type",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "OrderFrequency",
        headerText: "Order Frequency",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "ParentAccountName",
        headerText: "Parent Account Name",
        width: "210",
        disableHtmlEncode: false,
    },
    {
        field: "ParentAccountNumber",
        headerText: "Parent Account Number",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "ChildAccountNumber",
        headerText: "Child Account Number",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "ChildAccountName",
        headerText: "Child Account Name",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "ChildAccountAddress",
        headerText: "Child Account Address",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "OrderingPhysician",
        headerText: "Ordering Physician",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "PatientName",
        headerText: "Patient Name",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "Phlebotomist",
        headerText: "Phlebotomist",
        width: "230",
        disableHtmlEncode: false
    },
    {
        field: "PhlebEmpID",
        headerText: "Phleb Emp ID",
        width: "230",
        disableHtmlEncode: false
    },
    {
        field: "Region",
        headerText: "Region",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "Territory",
        headerText: "Territory",
        width: "190",
        disableHtmlEncode: false,
    },
    {
        field: "Zone",
        headerText: "Zone",
        width: "230",
        disableHtmlEncode: false,
    },
    {
        field: "PatientDOB",
        headerText: "Patient DOB",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
        type: "Date"
    },
    {
        field: "PatientGender",
        headerText: "Patient Gender",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "PatientLocation",
        headerText: "Patient Location",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "DropOffType",
        headerText: "Drop Off Type",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "DropLocationName",
        headerText: "Drop Location Name",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "TestCodes",
        headerText: "Test Code(s)",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "TestName",
        headerText: "Test Name(s)",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "CancellationReason",
        headerText: "Cancellation Reason",
        width: "255",
        disableHtmlEncode: false,
    },
    {
        field: "Notes",
        headerText: "Notes",
        width: "255",
        disableHtmlEncode: false,
    },
    {
        field: "LastUpdated",
        headerText: "Last Updated DateTime",
        width: "255",
        visible: true,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "OrderCreationUser",
        headerText: "Order Creation User",
        width: "255",
        visible: false
    },
    {
        field: "PatientLocation",
        headerText: "Patient Location",
        width: "255",
        visible: false
    },
    {
        field: "NotifiedPerson",
        headerText: "Notified Person",
        width: "255",
        visible: false
    },
    {
        field: "SupervisorName",
        headerText: "Supervisor Name",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "CheckIn",
        headerText: "Check-In Date/Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "CheckOut",
        headerText: "Check-Out Date/Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "DropOff",
        headerText: "Drop-Off Date/Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "OrderCollectionMethod",
        headerText: "Order Collection Method",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "CollectionDate",
        headerText: "Collection Date",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    }
];

export const ROUTINE_REDRAW_COLUMNS = [
    {
        field: "OrderStatus",
        headerText: "Status",
        width: "210",
        template: '<div>${customStatus(data)}</div>',
        disableHtmlEncode: false,
    },
    {
        field: "OrderNumber",
        headerText: "Order #",
        width: "210",
        disableHtmlEncode: false,
    },
    {
        field: "OrderCreated",
        headerText: "Order Created",
        width: "210",
        disableHtmlEncode: false,
        type: "Date"
    },
    {
        field: "OrderCreationMethod",
        headerText: "Order Creation Method",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "OrderType",
        headerText: "Order Type",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "OrderFrequency",
        headerText: "Order Frequency",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "ParentAccountName",
        headerText: "Parent Account Name",
        width: "210",
        disableHtmlEncode: false,
    },
    {
        field: "ParentAccountNumber",
        headerText: "Parent Account Number",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "ChildAccountNumber",
        headerText: "Child Account Number",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "ChildAccountName",
        headerText: "Child Account Name",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "ChildAccountAddress",
        headerText: "Child Account Address",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "OrderingPhysician",
        headerText: "Ordering Physician",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "PatientName",
        headerText: "Patient Name",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "Phlebotomist",
        headerText: "Phlebotomist",
        width: "230",
        disableHtmlEncode: false,
    },
    {
        field: "PhlebEmpID",
        headerText: "Phleb Emp ID",
        width: "230",
        disableHtmlEncode: false
    },
    {
        field: "Region",
        headerText: "Region",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "Territory",
        headerText: "Territory",
        width: "190",
        disableHtmlEncode: false,
    },
    {
        field: "Zone",
        headerText: "Zone",
        width: "230",
        disableHtmlEncode: false,
    },
    {
        field: "PatientDOB",
        headerText: "Patient DOB",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
        type: "Date"
    },
    {
        field: "PatientGender",
        headerText: "Patient Gender",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "PatientLocation",
        headerText: "Patient Location",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "DropOffType",
        headerText: "Drop Off Type",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "DropLocationName",
        headerText: "Drop Location Name",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "TestCodes",
        headerText: "Test Code(s)",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "TestName",
        headerText: "Test Name(s)",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "CancellationReason",
        headerText: "Cancellation Reason",
        width: "255",
        disableHtmlEncode: false,
    },
    {
        field: "Notes",
        headerText: "Notes",
        width: "255",
        disableHtmlEncode: false,
    },

    {
        field: "LastUpdated",
        headerText: "Last Updated DateTime",
        width: "255",
        visible: true,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "OrderCreationUser",
        headerText: "Order Creation User",
        width: "255",
        visible: false
    },
    {
        field: "PatientLocation",
        headerText: "Patient Location",
        width: "255",
        visible: false
    },
    {
        field: "SupervisorName",
        headerText: "Supervisor Name",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "CheckIn",
        headerText: "Check-In Date/Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "CheckOut",
        headerText: "Check-Out Date/Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "DropOff",
        headerText: "Drop-Off Date/Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "OrderCollectionMethod",
        headerText: "Order Collection Method",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "CollectionDate",
        headerText: "Collection Date",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    }
];

export const TIMED_DRAW_COLUMNS = [
    {
        field: "OrderStatus",
        headerText: "Status",
        width: "210",
        template: '<div>${customStatus(data)}</div>',
        disableHtmlEncode: false,
    },
    {
        field: "OrderNumber",
        headerText: "Order #",
        width: "210",
        disableHtmlEncode: false,
    },
    {
        field: "OrderCreated",
        headerText: "Order Created",
        width: "210",
        disableHtmlEncode: false,
        type: "Date"
    },
    {
        field: "OrderCreationMethod",
        headerText: "Order Creation Method",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "OrderType",
        headerText: "Order Type",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "OrderFrequency",
        headerText: "Order Frequency",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "CollectionDateTime",
        headerText: "Collection Date & Time",
        width: "210",
        visible: true,
        disableHtmlEncode: false,
        type: "Date"
    },
    {
        field: "ParentAccountName",
        headerText: "Parent Account Name",
        width: "210",
        disableHtmlEncode: false,
    },
    {
        field: "ParentAccountNumber",
        headerText: "Parent Account Number",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "ChildAccountNumber",
        headerText: "Child Account Number",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "ChildAccountName",
        headerText: "Child Account Name",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "ChildAccountAddress",
        headerText: "Child Account Address",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "OrderingPhysician",
        headerText: "Ordering Physician",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "PatientName",
        headerText: "Patient Name",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "Phlebotomist",
        headerText: "Phlebotomist",
        width: "230",
        disableHtmlEncode: false,
    },
    {
        field: "PhlebEmpID",
        headerText: "Phleb Emp ID",
        width: "230",
        disableHtmlEncode: false
    },
    {
        field: "Region",
        headerText: "Region",
        width: "220",
        disableHtmlEncode: false,
    },
    {
        field: "Territory",
        headerText: "Territory",
        width: "190",
        disableHtmlEncode: false,
    },
    {
        field: "Zone",
        headerText: "Zone",
        width: "230",
        disableHtmlEncode: false,
    },
    {
        field: "PatientDOB",
        headerText: "Patient DOB",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
        type: "Date"
    },
    {
        field: "PatientGender",
        headerText: "Patient Gender",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "PatientLocation",
        headerText: "Patient Location",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "DropOffType",
        headerText: "Drop Off Type",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "DropLocationName",
        headerText: "Drop Location Name",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "TestCodes",
        headerText: "Test Code(s)",
        width: "210",
        visible: false,
        disableHtmlEncode: false,
    },
    {
        field: "TestName",
        headerText: "Test Name(s)",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "CancellationReason",
        headerText: "Cancellation Reason",
        width: "255",
        disableHtmlEncode: false,
    },
    {
        field: "Notes",
        headerText: "Notes",
        width: "255",
        disableHtmlEncode: false,
    },

    {
        field: "LastUpdated",
        headerText: "Last Updated DateTime",
        width: "255",
        visible: true,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "OrderCreationUser",
        headerText: "Order Creation User",
        width: "255",
        visible: false
    },
    {
        field: "PatientLocation",
        headerText: "Patient Location",
        width: "255",
        visible: false
    },
    {
        field: "NotifiedPerson",
        headerText: "Notified Person",
        width: "255",
        visible: false
    },
    {
        field: "SupervisorName",
        headerText: "Supervisor Name",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "CheckIn",
        headerText: "Check-In Date/Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "CheckOut",
        headerText: "Check-Out Date/Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "DropOff",
        headerText: "Drop-Off Date/Time",
        width: "255",
        visible: false,
        filter: { operators: "contains" },
        type: "Date"
    },
    {
        field: "OrderCollectionMethod",
        headerText: "Order Collection Method",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    },
    {
        field: "CollectionDate",
        headerText: "Collection Date",
        width: "255",
        visible: false,
        filter: { operators: "contains" }
    }
];