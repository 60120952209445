import * as React from "react";
import _ from "lodash";
import usePlacesAutocomplete, { getDetails, getGeocode, getLatLng } from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { MAPBOX_API_KEY, TYPE_HOMEHEALTH, TYPE_ROUTINE, TYPE_STAT, TYPE_TIMEDDRAW } from "../../app/utils/Constants";
import axios from "axios";


export const AutoCompleteAddress = (props) => {
  const {
    handleChangeLatLng,
    newDropOff,
    pageType = "dropOff",
    handleChange,
    stateData,
    disabled = false,
    id,
    tempCourierAddress,
    setTempCourierAddress,
    setClearStartRoutineAddress,
    clearStartRoutineAddress,
    clearMapboxSearchText,
    setClearMapboxSearchText,
    updateHomeHealthValidateByMapbox,
    setClearMapboxSearchTextFacility,
    clearMapboxSearchTextFacility,
    clearMapboxSearchTextLabHospital,
    setClearMapboxSearchTextLabHospital,
    placeholder = "Search"
  } = props;

  const dropOffSearch =
    id !== "child-account-address" &&
    id !== "ad-search-address" &&
    id !== "ad-search-hospital-address" &&
    id !== "ad-search-lab-address";

  const newDropOffRef = React.useRef({});
  newDropOffRef.current = newDropOff;

  const stateDataRef = React.useRef({});
  stateDataRef.current = stateData;

  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: 'us'
      }
    }
  });

  React.useEffect(() => {
    if (pageType === TYPE_HOMEHEALTH) {
      if (status === 'ZERO_RESULTS') {
        if (value && value.length) {
          if (!data.length) {
            updateHomeHealthValidateByMapbox(false);
          } else {
            updateHomeHealthValidateByMapbox(true);
          }
        } else {
          updateHomeHealthValidateByMapbox(true);
        }
      } else if (status === 'OK') {
        updateHomeHealthValidateByMapbox(true);
      }
    }
  }, [status]);

  React.useEffect(() => {
    if (clearStartRoutineAddress) {
      setValue("");
      setTempCourierAddress({});
      handleChangeLatLng({
        ...newDropOffRef.current,
        dropOffLng: "",
        dropOffLat: "",
        timezone: ""
      });
      setClearStartRoutineAddress(false);
    }
  }, [clearStartRoutineAddress]);

  React.useEffect(() => {
    if (clearMapboxSearchText) {
      setValue("");
      setClearMapboxSearchText(false);
    }
  }, [clearMapboxSearchText]);

  React.useEffect(() => {
    if (clearMapboxSearchTextFacility) {
      setValue("");
      setClearMapboxSearchTextFacility(false);
    }
  }, [clearMapboxSearchTextFacility, setClearMapboxSearchTextFacility]);

  React.useEffect(() => {
    if (clearMapboxSearchTextLabHospital) {
      setValue("");
      setClearMapboxSearchTextLabHospital(false);
    }
  }, [clearMapboxSearchTextLabHospital, setClearMapboxSearchTextLabHospital]);

  const getAddressDetails = (address_components) => {
    const addressComponents = address_components || [];
    const streetNumber = addressComponents.find(component => component.types.includes('street_number'))?.long_name || '';
    const streetAddress = addressComponents.find(component => component.types.includes('route'))?.long_name || '';
    const city = addressComponents.find(component => component.types.includes('locality'))?.long_name || '';
    const city2 = addressComponents.find(component => component.types.includes('sublocality'))?.long_name || '';
    const state = addressComponents.find(component => component.types.includes('administrative_area_level_1'))?.short_name || '';
    const zip = addressComponents.find(component => component.types.includes('postal_code'))?.long_name || '';
    const country = addressComponents.find(component => component.types.includes('country'))?.short_name || '';

    return {
      streetNumber,
      streetAddress,
      city: city || city2,
      state,
      zip,
      country,
    };
  };

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const searchAddressFromMapboxWhenCityNotPresent = async (search_text) => {
    try {
      const response = await axios.get(`https://api.mapbox.com/search/geocode/v6/forward?q=${search_text}`, {
        params: {
          access_token: MAPBOX_API_KEY
        }
      });
      return response?.data?.features;
    } catch (e) {
      console.log(e);
    }
  }

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = (selectedData) => async () => {
    // When the user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(selectedData.description, false);
    clearSuggestions();

    const geoData = await getGeocode({ address: selectedData.description });
    const details = geoData[0] || {};
    const allDetails = getAddressDetails(details.address_components)
    let { city, state, zip, streetAddress, streetNumber } = allDetails || {};
    let { lat, lng } = getLatLng(geoData[0]);
    let address1 = `${streetNumber ? `${streetNumber} ` : ""}${streetAddress ? `${streetAddress}` : ""}`;

    // this check is only for pages other than dropoff
    if (pageType !== "dropOff" && !city) {
      const suggestions = await searchAddressFromMapboxWhenCityNotPresent(selectedData.description);
      if (suggestions && suggestions[0]) {
        lat = suggestions[0].geometry.coordinates[1];
        lng = suggestions[0].geometry.coordinates[0];
        if (suggestions[0].properties.context) {
          address1 = suggestions[0].properties.context?.street?.name;
          city = suggestions[0].properties.context?.place?.name;
          zip = suggestions[0].properties.context?.postcode?.name;
          state = suggestions[0].properties.context?.region?.region_code;
        }
      }
    }

    if (
      pageType === `dropOff-${TYPE_STAT}` ||
      pageType === `dropOff-${TYPE_ROUTINE}` ||
      pageType === `dropOff-${TYPE_TIMEDDRAW}` ||
      pageType === `dropOff-${TYPE_HOMEHEALTH}`

    ) {
      handleChange(
        {
          ...stateDataRef.current,
          dropOffLocation: {
            label: address1,
            value: {
              city,
              stateName: state,
              zip,
              address1,
              longitudeName: lng,
              latitudeName: lat,
            },
          },
        },
        !city || !state || !zip
      );
    } else if (pageType === "dropOff") {
      handleChangeLatLng({
        ...newDropOffRef.current,
        dropOffLng: lng,
        dropOffLat: lat
      });
      if (dropOffSearch) {
        setTempCourierAddress({
          ...newDropOffRef.current,
          ...allDetails,
          dropOffLng: lng,
          dropOffLat: lat
        });
      }
    } else {
      handleChange(
        {
          ...stateDataRef.current,
          city,
          stateName: state,
          zip,
          address1: address1,
          longitudeName: lng,
          latitudeName: lat,
        },
        !city || !state || !zip || !address1
      );
    }
  };


  const handleClear = () => {
    setValue('');
    if (
      pageType === `dropOff-${TYPE_STAT}` ||
      pageType === `dropOff-${TYPE_ROUTINE}` ||
      pageType === `dropOff-${TYPE_TIMEDDRAW}` ||
      pageType === `dropOff-${TYPE_HOMEHEALTH}`
    ) {
      handleChange(
        {
          ...stateDataRef.current,
          dropOffLocation: {
            city: "",
            stateName: "",
            zip: "",
            address1: "",
            longitudeName: "",
            latitudeName: "",
          },
        },
        false
      );
    } else if (pageType === "dropOff") {
      handleChangeLatLng({
        ...newDropOffRef.current,
        dropOffLng: "",
        dropOffLat: "",
        timezone: ""
      });
    } else {
      if (pageType === TYPE_HOMEHEALTH) {
        updateHomeHealthValidateByMapbox(true, true);
      } else {
        let newData = {
          ...stateDataRef.current,
          city: "",
          stateName: "",
          zip: "",
          address1: "",
          address2: "",
          longitudeName: "",
          latitudeName: "",
          timezone: ""
        }
        if (pageType === "facility") {
          newData.timezone = "";
        }
        handleChange(newData, false);
      }
    }
    if (dropOffSearch && tempCourierAddress && tempCourierAddress.place_name) {
      setTempCourierAddress({});
      handleChangeLatLng({
        ...newDropOffRef.current,
        dropOffLng: "",
        dropOffLat: "",
        timezone: ""
      });
    }
    clearSuggestions();
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <span>{secondary_text}</span>
        </li>
      );
    });

  return (
    <div ref={ref} className="autocomplete-address-wrapper position-relative">
      <span className="cus-search-icon"><span className="visually-hidden">Search icon </span></span>
      <input
        value={value}
        onChange={handleInput}
        disabled={!ready || disabled}
        placeholder={placeholder}
        id={id}
      />
      {value && <button type="button" onClick={handleClear} disabled={!value} className="cus-clear-icon autocomplete-clear-icon">
        <span className="visually-hidden">Clear</span>
      </button>}
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && <ul className="e-ddl e-popup cus-autocomplete-options">{renderSuggestions()}</ul>}
    </div>
  );
};
