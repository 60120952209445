import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ahaApi from "../../app/services/aha-api";
import { TYPE_HOMEHEALTH } from "../../app/utils/Constants";
const initialState = {
  value: [],
  loading: false,
};

export const getOrder = createAsyncThunk(
  "order/getOrder",
  async (data, { rejectWithValue }) => {
    const endpoint = data.type === TYPE_HOMEHEALTH ? 'getHomeHealthOrder' : 'getOrder';
    const response = await ahaApi.Account[endpoint](data.id)
      .then((res) => {
        let { content = {} } = res;

        res.content = {
            ...res.content,
            order_creation_datetime_for_modal: res.content.order_creation_datetime,
          };
        if (data.type === TYPE_HOMEHEALTH) {
          res.content.isGlobalSearch = data.isGlobalSearch || false
        }
        return res;
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrderList(state, action) {
      state.value = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getOrder.rejected, (state, err) => {
        state.loading = false;
        state.value = err.payload;
      });
  },
});

export const order = (state) => state.order.value;
export const orderLoader = (state) => state.order.loading;
export const { setOrderList } = orderSlice.actions;

export default orderSlice.reducer;
